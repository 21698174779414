import { MediaPlayer, MediaPlayerClass } from 'dashjs';
import { FC, useEffect, useRef, useState } from 'react';

const config = {
  file: {
    attributes: { controlsList: 'nodownload' }, // Prevent downloading
  },
};

interface MediaProps {
  url: string;
  onPlay?: () => void;
  onLoaded?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
  width?: string | number;
  height?: string | number;
  playsInline?: boolean;
  isPlaying?: boolean;
  isManifest?: boolean;
}

const Media: FC<MediaProps> = ({
  url,
  onPlay,
  onLoaded,
  onPause,
  onEnd,
  width = '100%',
  height = '100%',
  playsInline = false,
  isPlaying = false,
  isManifest = false,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [player, setPlayer] = useState<MediaPlayerClass>();

  useEffect(() => {
    if (videoRef.current && url && !player) {
      // Dash.js supports only .mpd files
      if (isManifest) {
        const mediaPlayer = MediaPlayer().create();
        mediaPlayer.initialize(videoRef.current, url, false);
        setPlayer(mediaPlayer);
      }

      const videoElement = videoRef.current;
      Object.entries(config.file.attributes).forEach(([key, value]) => {
        videoElement.setAttribute(key, value as string);
      });
    }
  }, [videoRef.current, url, isManifest]);

  useEffect(() => {
    if (videoRef.current && (player || !isManifest)) {
      handlePlayVideo(videoRef.current);
    }
  }, [videoRef.current, isPlaying, player, isManifest]);

  const handlePlayVideo = async (ref: HTMLVideoElement) => {
    if (isPlaying) {
      try {
        await ref.play();
      } catch (error: any) {
        console.error('Error playing video:', error);
      }
    } else {
      ref.pause();
    }
  };

  return (
    <video
      ref={videoRef}
      controls
      width={width}
      height={height}
      playsInline={playsInline}
      onPlay={onPlay}
      onPause={onPause}
      onEnded={onEnd}
      onLoadedMetadata={onLoaded}
      src={isManifest ? undefined : url}
    />
  );
};

export default Media;
