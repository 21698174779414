import { FC } from 'react';
import { IconButton } from '@mui/material';

import Media from 'Components/Media';
import Image from 'Components/Image';
import { AttachmentType } from 'Constants/enums';
import { Attachment } from 'Types/attachment.interface';
import { getAttachmentUrl } from "Utils/attachments";
import * as styles from './index.module.scss';

export interface BadgeAttachedProps {
  file: Attachment;
  removeImage?: (file: Attachment) => void;
}

const BadgeAttachedImage:FC<BadgeAttachedProps> = ({ file, removeImage }) => {
  const isVideo = file?.type === AttachmentType.Video;
  const { url, isManifest } = getAttachmentUrl(file);

  return (
    <div className={styles.attachWrapper}>
      <div className={isVideo ? styles.videoAttach : styles.attach}>
        {isVideo
          ? <Media url={url} isManifest={isManifest} />
          : (
            <Image
              className="image-post-v2"
              alt="cropped_img"
              src={url}
            />
          )}
      </div>
      {removeImage && (
        <div className={styles.removeOverlay}>
          <div className={styles.removeContainer}>
            <IconButton onClick={() => removeImage(file)}>
              <i className="icon-trash" />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default BadgeAttachedImage;
